
























import { Component, Vue } from "vue-property-decorator";
// import ViewOnly from "@/views/archive/ViewOnly.vue";
import View from "@/views/View.vue";
import { ContestEntry } from "@/models/contest";
import ContestService from "@/services/contest_service";
import { ConfigCreator } from "@/mixins/configurator";
import { mixins } from "vue-class-component";
import { ViewerError } from "@/services/error_service";
import ViewerPlugin from "@/plugins/viewer";
import { Stem } from "@/models/configurator/configurator_options";
import CleanHeader from "@/components/designer/header/CleanHeader.vue";
@Component({
  components: {
    CleanHeader,
    // ViewOnly,
    View,
  },
})
export default class ContestEntryView extends mixins(ConfigCreator) {
  protected contestService = new ContestService();
  protected entry: null | ContestEntry = null;
  protected loader: any = null;

  async created(): Promise<void> {
    this.loader = this.$loading.show(
      { opacity: 0.8 },
      {
        before: this.$createElement("h1", "Loading contest entry..."),
      }
    );
  }

  protected async frameLoaded(): Promise<void> {
    ViewerPlugin.init(Vue);
    try {
      this.entry = await this.contestService.getFile(this.$route.params.id);
      await this.transformConfigFileAndSaveToStore(this.entry.config_file);
      const data = await this.transformDataToViewerJson();
      await this.$viewer.SetAssetPath(process.env.VUE_APP_CDN_URL + "/sd");
      /** Exception logic for bases that include 18ST2 stems in the base's model name. These are specific to giant umbrellas, which MUST have an 18ST2 stem
       */
      if (
        data.base.base &&
        data.base.base !== Stem.Stem18ST2 &&
        data.base.base.indexOf("18ST2") > -1
      ) {
        data.base.base = data.base.base.slice(0, -6);
        data.base.baseStem = Stem.Stem18ST2;
      }
      await this.$viewer.StartApp(data);
      this.loader.hide();
    } catch (err) {
      this.loader.hide();
      ViewerError.redirect(err);
    }
  }
}
